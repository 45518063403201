import i18n from "i18next";
//import LanguageDetector from "i18next-browser-languagedetector"
import CookieManager from './cookieManager';

import english from "../translations/english.json";
import arabic from "../translations/arabic.json";
import cookieManager from "./cookieManager";

i18n.init({
    lng: CookieManager.getLocale()?.toUpperCase() ?? "AR",
    resources: {
        EN: english,
        AR: arabic
    },
    ns: ["common","auth","claim"],
    defaultNS: "common",
    keySeparator: false,
    interpolation:{
        escapeValue: false,
        formatSeparator: ","
    },
    react: {
        wait: true
    }


})

export default i18n;


export const ChangeLocale = (locale:string) => {
    i18n.changeLanguage(locale.toUpperCase());
    cookieManager.setLocale(locale.toUpperCase());
}



import {SET_IS_LOADING, SET_ALERT, HIDE_ALERT, SET_LOCALE} from '../types';
import Action from "../../models/action";
import {ChangeLocale} from "../../services/i18n";


export const SetIsLoading = (isLoading: boolean ):Action => {

    return {
        type: SET_IS_LOADING,
        payload:{
            isLoading
        }
    }
}

export const SetAlert = (key:string,isError?:boolean,message?:string):Action => {
    return {
        type: SET_ALERT,
        payload:{
            key,
            isError,
            message
        }
    }
}


export const SetLocale = (locale:string):Action => {
    ChangeLocale(locale)
    return {
        type: SET_LOCALE,
        payload: {locale}
    }
}


export const HideAlert = ():Action =>{
    return {
        type: HIDE_ALERT,
    }
}

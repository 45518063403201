import { ApiResponse, ClientFactory, GetClientConfig } from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import CookieManager from "../services/cookieManager";
import RequestOptions from "@simplify9/simplyapiclient/dist/Models/RequestOptions";
import store from "../store";
import { SetAlert } from "../store/actions/ui";

interface Options extends RequestOptions {
    ignoreError?: boolean
}

class ErrorNotifierClient {
    client: IClient;

    pageSize: number;

    constructor(baseUrl: string) {

        this.client = ClientFactory({
            baseUrl: baseUrl,
            authType: "bearer",
            getBearer: () => CookieManager.getAccessToken(),
        });
        this.pageSize = 20;
    }

    async getAsync(url: string, options?: Options) {
        const rs: ApiResponse = await this.client.SimplyGetAsync(url, options);
        this.notifyError(rs, options);
        return rs;
    }

    async postAsync(url: string, body: any, options?: Options) {
        const rs: ApiResponse = await this.client.SimplyPostAsync(url, body, options);
        this.notifyError(rs, options);
        return rs;
    }

    async putAsync(url: string, body: any, options?: Options) {
        const rs: ApiResponse = await this.client.SimplyPutAsync(url, body, options);
        this.notifyError(rs, options);
        return rs;
    }

    async deleteAsync(url: string, body: any, options?: Options) {
        const rs: ApiResponse = await this.client.SimplyDeleteAsync(url, body, options);
        this.notifyError(rs, options);
        return rs;
    }

    async postFormAsync(url: string, body: any, options?: Options) {
        const rs: ApiResponse = await this.client.SimplyPostFormAsync(url, body, options);
        this.notifyError(rs, options);
        return rs;
    }

    notifyError(rs: ApiResponse, options?: Options) {

        if (rs.succeeded || options?.ignoreError)
            return;

        switch (rs.status) {
            case 400:
                if (rs.data.SWException) {
                    store.dispatch(SetAlert("Error", true, rs.data.SWException[0]));
                }else{
                    const keys = Object.keys(rs.data);
                    keys.map(i=> {

                        store.dispatch(SetAlert(i, true, Array.isArray(rs.data[i]) ? rs.data[i][0] : rs.data[i].toString()));

                    })

                }
                break;
            case 404:
                if (typeof rs.data === 'string' || rs.data instanceof String) {
                    store.dispatch(SetAlert("Error", true, rs.data.toString()));
                } else {
                    store.dispatch(SetAlert("Error", true, "Not found"));
                }
                break;
            case 500:
                store.dispatch(SetAlert("Error", true, "Internal server error occured "));
                break;
            case 502:
                store.dispatch(SetAlert("Error", true, "Service is temporarily unavailable "));
                break;
            case 0:
                store.dispatch(SetAlert("Error", true, "Service is temporarily unavailable "));
                break;
        }
    }

}

export default ErrorNotifierClient;

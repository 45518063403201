import IAppStateModel from "../models/appState";
import { CommonSearchInitialValues } from "../models/commonSearch";
import { SupplierOrderyByKeys } from "../models/supplier";

const initialState: IAppStateModel = {
    isLoading: false,
    isLoggingIn: false,
    lookups: {
        manufacturers: [],
        models: [],
        categories: [],
        countries: [],
        engineTypes: [],
        colors: [],
        bodyStyles: [],
        bodyDescriptions: [],
        roles: [],
    },
    settings: {
        search: {
            limit: 20,
            offset: 0
        },
    },
    purchaseOrders: {
        search: {
            limit: 20,
            offset: 0,
            ...CommonSearchInitialValues,
        }
    },
    quotations: {
        search: {
            limit: 20,
            offset: 0,
            status: "pending",
        },
    },
    requests: {
        search: {
            limit: 20,
            offset: 0,
            status: "sent",
            claimNumber: "",
        },
    },
    cases: {
        search: {
            limit: 20,
            offset: 0,
            orderByDesc: true
        },
    },
    users: {
        search: {
            limit: 20,
            offset: 0,
            lookup: false,
        },
    },
    suppliers: {
        search: {
            limit: 20,
            offset: 0,
            orderBy: SupplierOrderyByKeys.CreatedOn,
            orderByDesc: true,
            referenceNumber: null,
            email: "",
            name: "",
            mobile: ""
        },
    },
    repairOrders: {
        search: {
            limit: 20,
            offset: 0
        }
    },
    customers: {
        search: {
            limit: 20,
            offset: 0,
            lookup: false,
            mobile: "",
            plateNumber: "",
            licensedFrom: undefined,
            licensedTo: undefined
        }
    },
    customerRequests: {
        search: {
            limit: 20,
            offset: 0,
            status: "sent",
        },
    },
};

export default initialState;

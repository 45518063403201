export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_LOCALE = "SET_LOCALE";

export const SET_LOGGING_IN = "LOGGING_IN";
export const SET_PROFILE = "SET_PROFILE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const SET_ACCOUNT_DATA = "SET_ACCOUNT_DATA"
export const SET_CURRENT_SUPPLIER_DATA = "SET_CURRENT_SUPPLIER_DATA"
export const SET_CURRENT_GARAGE_DATA = "SET_CURRENT_GARAGE_DATA";
export const SET_PASSWORD_MODAL = 'SET_PASSWORD_MODAL';

export const SET_ALERT = "SET_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

export const SET_SEARCH_QUOTATION = 'SET_SEARCH_QUOTATION';
export const SET_QUOTATIONS = 'SET_QUOTATIONS';

export const SET_SEARCH_CASES = 'SET_SEARCH_CASES';
export const SET_CASES = 'SET_CASES';
export const SET_DRAFT_REQUEST = 'SET_DRAFT_REQUEST';

export const SET_FAMILIES = "SET_FAMILIES";
export const SET_SEARCH_PARTS = "SET_SEARCH_PARTS";
export const SET_PARTS = "SET_PARTS";
export const SET_CAR_CATALOG = 'SET_CAR_CATALOG';

export const SET_USER_LOOKUPS = "SET_USER_LOOKUPS";

export const SET_SETTINGS = 'SET_SETTINGS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';


export const SET_REQUESTS = 'SET_REQUESTS';
export const SET_SEARCH_REQUEST = 'SET_SEARCH_REQUEST';


export const SET_SEARCH_SUPPLIERS = 'SET_SEARCH_SUPPLIERS';
export const SET_SUPPLIERS = 'SET_SUPPLIERS';

export const SET_PURCHASE_ORDERS = 'SET_PURCHASE_ORDERS';
export const SET_SEARCH_PO = 'SET_SEARCH_PO';

export const SET_SEARCH_USERS = 'SET_SEARCH_USERS';
export const SET_USERS = 'SET_USERS';
export const SET_VEHICLE_META = 'SET_VEHICLE_META';
export const SET_META_DATA = 'SET_META_DATA';
export const SET_SUPPLIERS_LOOKUP = 'SET_SUPPLIERS_LOOKUP';

export const SET_MODELS = 'SET_MODELS';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_COUNTRIES_META = 'SET_COUNTRIES_META';

export const SET_REPAIR_ORDERS = 'SET_REPAIR_ORDERS';
export const SET_SEARCH_REPAIR_ORDERS = 'SET_SEARCH_REPAIR_ORDERS';

export const SET_CUSTOMERS = 'SET_CUSTOMERS'
export const SET_SEARCH_CUSTOMERS = 'SET_SEARCH_CUSTOMERS'

export const SET_ROLES_META = 'SET_ROLES_META';

export const SET_CUSTOMER_REQUESTS = 'SET_CUSTOMER_REQUESTS';
export const SET_SEARCH_CUSTOMER_REQUEST = 'SET_SEARCH_CUSTOMER_REQUEST';

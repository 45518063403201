import React from 'react';
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";
import {Helmet} from "react-helmet";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import "./scss/style.scss";
import Loading from './components/shared/loading';
import {useTranslation} from "react-i18next";
import Config from './config';
import cookieManager from "./services/cookieManager";
import {SetBaseUrl, SetClientConfig} from "@simplify9/simplyapiclient";
import {useDispatch} from "react-redux";
import {Logout} from "./store/actions/auth";

const MainLayout = React.lazy(() => import('./components/layout/main'));
const AuthLayout = React.lazy(() => import('./components/layout/auth'));

const faviconPath = `${Config.images?.FaviconPath}`

function App() {
    const dispatch = useDispatch();
    const {i18n} = useTranslation();
    React.useEffect(() => {
        let storedLocale = cookieManager.getLocale();
        if (storedLocale !== i18n.language) cookieManager.setLocale(i18n.language);

        SetClientConfig({
            authType: "bearer",
            getBearer: () => cookieManager.getAccessToken(),
            onAuthFail: () => dispatch(Logout()),
        });
        SetBaseUrl(`${Config.baseUrl}`);
    })
    return (
        <>
            <Helmet>
                <link rel="icon" type="image/png" href={faviconPath} sizes="16x16"/>

            </Helmet>
            <Router>
                <div
                    dir={i18n.language.slice(0, 2).toUpperCase() === "EN" ? "ltr" : "rtl"}
                    style={i18n.language.slice(0, 2).toUpperCase() === "AR" ? {textAlign: 'right'} : {}}
                >
                    <React.Suspense fallback={Loading}>
                        <Switch>
                            <Route path="/login">
                                <AuthLayout/>
                            </Route>
                            <Route path="/">
                                <MainLayout/>
                            </Route>
                        </Switch>
                    </React.Suspense>
                </div>
            </Router>
        </>
    );


}

export default App;

import IUser from "./user";

export default interface ISupplier {
    id?: string;
    name: string;
    address?: IAddressModel;
    referenceNumber?: string;
    phone?: string;
    mobile: string
    email?: string;
    inactive?: boolean;
    users?: IUser[];
    specialty: ISupplierMap;
    discountPercent: number
    supplierType: string
}


export interface IAddressModel {
    city: string
    area: string
    country: string
    addressUrl: string
    longitude: string
    latitude: string
}

export interface ICreateSupplierUser {
    name: string;
    email: string;
    mobile: string;
}

export interface ISearchSupplier {
    limit: number;
    offset: number;
    //sorting
    orderBy: SupplierOrderyByKeys;
    orderByDesc: boolean;
    //filters
    name?: string;
    mobile?: string;
    email?: string;
    active?: boolean;
    referenceNumber?: number | null;
    vehicleManufacturerId?: string;
    vehicleModelId?: string;
    vehicleCategoryId?: string;
    supplierType?: string;
    vehicleBodyStylesId?: string;
    vehicleBodyDescriptionsId?: string;
    status?: string

}

export enum SupplierOrderyByKeys {
    Name = "Name",
    Mobile = "Mobile",
    Email = "Email",
    ReferenceNumber = "ReferenceNumber",
    CreatedOn = "CreatedOn",
}

export interface ICreateSupplier {
    nameAr?: string;
    nameEn?: string;
    referenceNumber?: string;
    country?: string;
    city?: string;
    area?: string;
    phone?: string;
    mobile?: string;
    email?: string;
    supplierType?: string | number;
}

export interface IUpdateSupplier {
    discountPercent: number
}

export interface ISupplierMap {
    vehicleBodyDescriptions?: number[];
    vehicleCategoriesIds?: number[];
    vehicleManufacturersIds?: number[];
    vehicleModelsIds?: number[];
    vehicleBodyStyleIds?: number[];
    vehicleBodyDescriptionsIds?: number[];
    includeAccidentVehicleManufacturers?: boolean,
    includeCustomerVehicleManufacturers?: boolean,
    includeAccidentVehicleModels?: boolean,
    includeCustomerVehicleModels?: boolean,
    includeAccidentVehicleBodyDescriptions?: boolean,
    includeCustomerVehicleBodyDescriptions?: boolean,
}

export interface ICreateUserModel {
    name?: string;
    email?: string;
    mobile?: string;
}

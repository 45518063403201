const dev = {
  baseUrl: "https://localhost:5001/api/",
};
const stg = {
  // baseUrl: "https://stg-autoparts.gig.com.jo/api/",
  baseUrl: "https://insureapp-stg.sf9.io/api/",
};

const gig = {
  images: {
    LogoPath: "/giglogo.png",
    LogoNegativePath: "/giglogon.png",
    FaviconPath: "/gigfavicon.ico",
  },
};

const config = (env: any) => {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return dev;
    case "stg":
      return stg;
    case "gig":
      return gig;
  }
};

export default {
  // Add common config values here
  baseUrl: "/api/",
  GIG_BASE_URL: "https://e-services.gig.com.jo/api/",
  ReCAPTCHA_Key: "6LcUmOgpAAAAAAXlCGFOcg8j8EkejS5nLObCHGUQ",
  images: {
    LogoPath: "/logo.png",
    LogoNegativePath: "/logo.png",
    FaviconPath: "/favicon.ico",
  },
  ...config(process.env.REACT_APP_STAGE),
};

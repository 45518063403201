import {
    HIDE_ALERT,
    LOGOUT,
    SET_ACCOUNT_DATA,
    SET_ALERT,
    SET_CASES,
    SET_COUNTRIES_META,
    SET_CURRENT_GARAGE_DATA,
    SET_CURRENT_SUPPLIER_DATA,
    SET_FAMILIES,
    SET_IS_LOADING,
    SET_LOCALE,
    SET_LOGGING_IN, SET_META_DATA,
    SET_PARTS,
    SET_PROFILE,
    SET_PURCHASE_ORDERS,
    SET_QUOTATIONS,
    SET_REQUESTS,
    SET_SEARCH_CASES,
    SET_SEARCH_PARTS,
    SET_SEARCH_QUOTATION,
    SET_SEARCH_REQUEST,
    SET_SEARCH_SUPPLIERS,
    SET_SEARCH_USERS,
    SET_SUPPLIERS,
    SET_SUPPLIERS_LOOKUP,
    SET_USER_LOOKUPS,
    SET_USERS,
    SET_VEHICLE_META,
    SET_REPAIR_ORDERS,
    SET_SEARCH_PO, SET_SEARCH_REPAIR_ORDERS, SET_CUSTOMERS, SET_SEARCH_CUSTOMERS,
    SET_ROLES_META,
    SET_CUSTOMER_REQUESTS,
    SET_SEARCH_CUSTOMER_REQUEST,
    SET_PASSWORD_MODAL
} from './types';
import InitialState from './initialState';
import IAppStateModel from '../models/appState';


const appReducer = (state = InitialState, action: any): IAppStateModel => {
    switch (action.type) {

        case SET_ACCOUNT_DATA:
            return {
                ...state,
                profileData: action.payload
            }
        case SET_CURRENT_SUPPLIER_DATA:
            return {
                ...state,
                currentSupplierData: action.payload
            }
        case SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        case SET_LOCALE:
            return {
                ...state,
                locale: action.payload.locale
            }
        case SET_LOGGING_IN:
            return {
                ...state,
                isLoggingIn: action.payload.isLoggingIn
            }
        case SET_PROFILE:
            return {
                ...state,
                profile: action.payload
            }
        case LOGOUT:
            return {
                ...state,
                profile: undefined
            }
        case SET_ALERT:
            return {
                ...state,
                alert: {
                    ...action.payload,
                    show: true,

                }
            }
        case HIDE_ALERT:
            return {
                ...state,
                alert: {
                    show: false
                }
            }
        case SET_SEARCH_QUOTATION:
            return {
                ...state,
                quotations: {
                    ...state.quotations,
                    search: action.payload

                }
            }
        case SET_QUOTATIONS:
            return {
                ...state,
                quotations: {
                    ...state.quotations,
                    results: action.payload
                }
            }


        case SET_SEARCH_CASES:
            return {
                ...state,
                cases: {
                    ...state.cases,
                    search: action.payload

                }
            }
        case SET_CASES:
            return {
                ...state,
                cases: {
                    ...state.cases,
                    results: action.payload
                }
            }

        case SET_SEARCH_PARTS:
            return {
                ...state,
                catalog: {
                    ...state.catalog,
                    search: action.payload
                }
            }

        case SET_PARTS:
            return {
                ...state,
                catalog: {
                    ...state.catalog,
                    parts: action.payload
                }
            }

        case SET_FAMILIES:
            return {
                ...state,
                catalog: {
                    ...state.catalog,
                    families: action.payload,

                }
            }

        case SET_SUPPLIERS_LOOKUP: {
            return {
                ...state,
                lookups: {
                    ...state.lookups,
                    suppliers: action.payload
                }
            }
        }
        case SET_USER_LOOKUPS:
            return {
                ...state,
                lookups: {
                    ...state.lookups,
                    inspectors: action.payload.inspectors,
                    agents: action.payload.agents
                }
            }
        case SET_REQUESTS:
            return {
                ...state,
                requests: {
                    ...state.requests,
                    results: action.payload
                }
            }
        case SET_SEARCH_REQUEST:
            return {
                ...state,
                requests: {
                    ...state.requests,
                    search: action.payload
                }
            }

        case SET_SEARCH_SUPPLIERS:
            return {
                ...state,
                suppliers: {
                    ...state.suppliers,
                    search: action.payload

                }
            }
        case SET_PURCHASE_ORDERS:
            return {
                ...state,
                purchaseOrders: {
                    ...state.purchaseOrders,
                    results: action.payload

                }
            }
        case SET_SEARCH_PO:
            return {
                ...state,
                purchaseOrders: {
                    ...state.purchaseOrders,
                    search: action.payload
                }
            }
        case SET_SUPPLIERS:
            return {
                ...state,
                suppliers: {
                    ...state.suppliers,
                    results: action.payload
                }
            }

        case SET_SEARCH_USERS:
            return {
                ...state,
                users: {
                    ...state.users,
                    search: action.payload

                }
            }
        case SET_USERS:
            return {
                ...state,
                users: {
                    ...state.users,
                    results: action.payload
                }
            }


        case SET_VEHICLE_META:
            return {
                ...state,
                lookups: {
                    ...state.lookups, ...action.payload
                }
            }
        case SET_META_DATA:
            return {
                ...state,
                metaData: action.payload
            }
        case SET_COUNTRIES_META:
            return {
                ...state,
                lookups: {
                    ...state.lookups, countries: [...state.lookups.countries || [], ...action.payload]
                }
            }

        case SET_REPAIR_ORDERS:
            return {
                ...state,
                repairOrders: {
                    ...state.repairOrders,
                    results: action.payload

                }
            }
        case SET_SEARCH_REPAIR_ORDERS:
            return {
                ...state,
                repairOrders: {
                    ...state.repairOrders,
                    search: action.payload
                }
            }

        case SET_CUSTOMERS:
            return {
                ...state,
                customers: {
                    ...state.customers,
                    results: action.payload
                }
            }
        case SET_SEARCH_CUSTOMERS:
            return {
                ...state,
                customers: {
                    ...state.customers,
                    search: action.payload
                }
            }
        case SET_ROLES_META:
            return {
                ...state,
                lookups: {
                    ...state.lookups, roles: [...state.lookups.roles || [], ...action.payload]
                }
            }
        case SET_CUSTOMER_REQUESTS:
            return {
                ...state,
                customerRequests: {
                    ...state.customerRequests,
                    results: action.payload
                }
            }
        case SET_SEARCH_CUSTOMER_REQUEST:
            return {
                ...state,
                customerRequests: {
                    ...state.customerRequests,
                    search: action.payload
                }
            }

        case SET_PASSWORD_MODAL:
            return {
                ...state,
                passwordModal: action.payload,
            };


        default:
            return state
    }
}

export default appReducer;

import { ApiResponse } from "@simplify9/simplyapiclient";
import CookieManager from "../services/cookieManager";
import IRegister from "../models/register";
import Config from "../config";
import ErrorNotifierClient from "./ErrorNotifierClient";
import { ISearchNotificationModel } from "../models/user";
import { OtpModel } from "../components/views/auth/forgetPasswordModal";

class AuthClient {
    client: ErrorNotifierClient;
    pageSize: number;
    locale: string;

    constructor() {
        this.client = new ErrorNotifierClient(Config.baseUrl ?? "");
        this.pageSize = 20;
        this.locale = CookieManager.getLocale();
    }

    async SwitchTenant(id: string): Promise<ApiResponse> {
        const rs: ApiResponse = await this.client.postAsync("accounts/SwitchTenant", {
            parentId: id
        });
        return rs;
    }

    async Login(username: string, password: string, captchaToken: string): Promise<ApiResponse> {
        const rs: ApiResponse = await this.client.postAsync("accounts/login", {
            email: username,
            password,
            captchaToken
        });
        return rs;
    }

    async GetProfile(search?: ISearchNotificationModel): Promise<ApiResponse> {
        let query: string = `accounts/profile?locale=${this.locale}&limit=${search?.limit ?? ''}&offset=${search?.offset ?? ''}`;
        if (search?.customerNotifications == true)
            query += "&customerNotifications=true"
        const rs: ApiResponse = await this.client.getAsync(query);

        return rs;
    }

    async searchSoaAsync(
        from: Date,
        to: Date,
    ): Promise<ApiResponse> {
        let path: string = `accounts/soa?locale=${this.locale}`;

        console.log(from)
        let dateFrom = new Date(from);
        dateFrom.setUTCHours(0, 0, 0);
        path += `&from=${dateFrom.toUTCString()}`;
        console.log(dateFrom)

        let dateTo = new Date(to);
        dateTo.setUTCHours(23, 59, 59);
        path += `&to=${dateTo.toUTCString()}`;

        const res = await this.client.getAsync(path);

        return res;
    };

    async markAllANNotificationsAsReadAsync(target?: string) {
        const query: string = `notifications/readAll?notificationTarget=${target}`;
        return await this.client.getAsync(query, {});
    };

    async markAsOpenedAsync(id: string) {
        const query: string = `notifications/${id}/markAsOpened`;
        return await this.client.getAsync(query, {});
    };

    async Register(info: IRegister): Promise<ApiResponse> {
        const rs: ApiResponse = await this.client.postAsync(
            "accounts/register",
            info
        );
        return rs;
    }

    async ResetPassword(email: string): Promise<ApiResponse> {

        const rs = await this.client.postAsync("accounts/reset", {
            email,
        });

        return rs;
    }
    async forgetPasswordAsync(email: string): Promise<ApiResponse> {

        const rs = await this.client.postAsync("accounts/sms", {
            email,
        });

        return rs;
    }

    async verifyOTPAsync(model?: OtpModel): Promise<ApiResponse> {

        const rs = await this.client.postAsync("accounts/verify",
            model,
        );

        return rs;
    }
}

export default AuthClient;

import { CSpinner } from "@coreui/react";
import React from "react";

const Loading = () => {
  return (
      <div
          className="overlay spinner w-100 h-100"
      >
        <CSpinner
          className="mx-auto d-block"
          color="secondary"
          style={{ width: "6em", height: "6em" }}
        />
      </div>
  );
};

export default Loading;

import Action from "../../models/action";
import { LOGOUT, SET_ACCOUNT_DATA, SET_CURRENT_SUPPLIER_DATA, SET_LOGGING_IN, SET_PROFILE, SET_PASSWORD_MODAL } from "../types";
import cookieManager from "../../services/cookieManager";
import jwt from "jsonwebtoken";
import AuthClient from "../../data/authClient";
import { SetIsLoading } from "./ui";
import { CurrentSupplierData, ParentModel, ProfileModel } from "../../models/profileModel";
import { ISearchNotificationModel } from "../../models/user";


export const ProfileRetrieved = (jwtToken: string) => {
    const info = jwt.decode(jwtToken)! as { [k: string]: any };
    cookieManager.setAccessToken(jwtToken);
    return {
        type: SET_PROFILE,
        payload: {
            id: info['nameid'],
            nameAr: info['NameAr'],
            nameEn: info['NameEn'],
            role: info['role'],
            supplierId: info['SupplierId'],
            mainUser: info['MainUser']
        }
    }
}

export const onSwitchTenant = (data: ParentModel, onResponse: () => void) => {
    return async (dispatch: any, getState: any) => {
        const authClient = new AuthClient()
        cookieManager.setCookieValue("SUPPLIER", data.supplierType)
        dispatch(setCurrentSupplierData({ id: data.id.toString(), type: data.supplierType }))
        const res = await authClient.SwitchTenant(data.id.toString());
        if (res.succeeded) {
            dispatch(ProfileRetrieved(res.data.jwt));
            onResponse()
        }
    };
}

export const GetProfile = (search?: ISearchNotificationModel) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true));
        const authClient = new AuthClient()
        const data = await authClient.GetProfile(search)
        //todo name localization
        if (data.succeeded) {
            dispatch(setProfileData(data.data))
        }

        dispatch(SetIsLoading(false));
    };
}

export const markAllANNotificationsAsRead = (target?: string, search?: ISearchNotificationModel) => {
    return async (dispatch: (arg0: any) => void) => {
        const authClient = new AuthClient()
        await authClient.markAllANNotificationsAsReadAsync(target);
        dispatch(GetProfile(search))
    };
};

export const setProfileData = (data: ProfileModel) => {
    return {
        type: SET_ACCOUNT_DATA,
        payload: data
    }

}
export const setCurrentSupplierData = (data: CurrentSupplierData) => {
    return {
        type: SET_CURRENT_SUPPLIER_DATA,
        payload: data
    }

}

export const Logout = () => {
    cookieManager.deleteAuthenticationCookies();

    return {
        type: LOGOUT
    }
}


export const SetLoggingIn = (loggingIn: boolean): Action => {
    return {
        type: SET_LOGGING_IN,
        payload: {
            loggingIn
        }
    }
}

export const setPasswordModal = (passwordModal: boolean) => {
    return {
        type: SET_PASSWORD_MODAL,
        payload: passwordModal,
    };
};











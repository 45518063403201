export interface CommonSearchType {
    plateNumber: string,
    claimNumber: string,
    claimantName: string,
    createdOnFrom?: Date,
    createdOnTo?: Date,
    isPaid?: boolean
}

export const CommonSearchInitialValues = {
    plateNumber: '',
    claimNumber: '',
    claimantName: '',
    createdOnFrom: undefined,
    createdOnTo: undefined,
    isPaid: undefined,
}